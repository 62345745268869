import './App.css';
import React from 'react';
import HomePage from './Components/HomePage';
import {BrowserRouter, Routes, Route} from 'react-router-dom';


function App() {
  return (
    <div className="App">
        <BrowserRouter>
      <Routes>
        <Route path='/' element={<HomePage/>}/>

      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
