import React from 'react';

const Nav = () => {
  return (
    <nav className="navbar">
   
        <div className="navbar-left">
          <a className="navbar-name">Bryan Pablo</a>
      
      </div>
    </nav>
  );
};

export default Nav;
